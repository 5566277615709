import styled from "@emotion/styled";
import { Badge } from "@mui/material";

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    right: 3px;
    top: 5px;
    padding: 0px 4px;
  }
`;
